import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useTranslation } from "react-i18next";

import {
  PageWrapper,
  TransitionWrapper,
  Title,
  Subtitle,
  TechnologiesWrapper,
  ItemWrapper,
  TextWrapper,
  Text,
  HorizontalDivider,
  ImagesWrapper,
  Image,
} from "./style";
import { DataType, DataKeysType } from "../utils";
import { PageChange } from "../../../../../common/Scroll";
import { fixConjunctions } from "../../../../../../utils/dropTheWord";
import { PageTitle } from "../../../../../common/PageTitle";

interface TechnologiesProps {
  pageChange: PageChange;
  transitionBlocks: JSX.Element[][];
}

const Technologies: React.FC<TechnologiesProps> = ({
  pageChange,
  transitionBlocks,
}) => {
  const { t } = useTranslation("main");
  const data = useStaticQuery(imagesQuery) as DataType;
  const categories = Object.keys(data) as DataKeysType[];

  return (
    <>
      <PageTitle subtitle={fixConjunctions(t(`technologies_page_title`))} />
      <PageWrapper animation={pageChange}>
        <Title
          dangerouslySetInnerHTML={{
            __html: fixConjunctions(t(`technologies_title`)),
          }}
          color="#013CFF"
        />
        <Title
          dangerouslySetInnerHTML={{
            __html: fixConjunctions(t(`technologies_subtitle`)),
          }}
        />
        <Subtitle
          dangerouslySetInnerHTML={{
            __html: fixConjunctions(t(`technologies_description`)),
          }}
        />
        <TechnologiesWrapper>
          {categories.map((category, i) => (
            <ItemWrapper key={i}>
              <ImagesWrapper>
                {data[category] &&
                  data[category].nodes.map((image, index, originalArray) => (
                    <Image
                      fluid={image.childImageSharp.fluid}
                      isFullSize={index == 0 && originalArray.length === 1}
                      key={index}
                    />
                  ))}
              </ImagesWrapper>
              <TextWrapper>
                <Text>{t(category)}</Text>
                <HorizontalDivider />
              </TextWrapper>
            </ItemWrapper>
          ))}
        </TechnologiesWrapper>

        <TransitionWrapper animation={pageChange}>
          {transitionBlocks}
        </TransitionWrapper>
      </PageWrapper>
    </>
  );
};

export const imagesQuery = graphql`
  {
    tvSystem: allFile(
      filter: { relativeDirectory: { eq: "technologies/SmartTV" } }
      sort: { order: ASC, fields: [name] }
    ) {
      nodes {
        name
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
    IOTAndSmartCity: allFile(
      filter: { relativeDirectory: { eq: "technologies/IOTAndSmartCity" } }
      sort: { order: ASC, fields: [name] }
    ) {
      nodes {
        name
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
    mobileApps: allFile(
      filter: { relativeDirectory: { eq: "technologies/mobileApps" } }
      sort: { order: ASC, fields: [name] }
    ) {
      nodes {
        name
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
    bigData: allFile(
      filter: { relativeDirectory: { eq: "technologies/bigData" } }
      sort: { order: ASC, fields: [name] }
    ) {
      nodes {
        name
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
    databasesAndBackOfficeSystems: allFile(
      filter: {
        relativeDirectory: { eq: "technologies/databasesAndBackOfficeSystems" }
      }
      sort: { order: ASC, fields: [name] }
    ) {
      nodes {
        name
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
    videoStreamAnalysis: allFile(
      filter: { relativeDirectory: { eq: "technologies/videoStreamAnalysis" } }
      sort: { order: ASC, fields: [name] }
    ) {
      nodes {
        name
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
    qualityAssuranceAndArchitecture: allFile(
      filter: {
        relativeDirectory: {
          eq: "technologies/qualityAssuranceAndArchitecture"
        }
      }
      sort: { order: ASC, fields: [name] }
    ) {
      nodes {
        name
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
    businessAnalytics: allFile(
      filter: { relativeDirectory: { eq: "technologies/businessAnalytics" } }
      sort: { order: ASC, fields: [name] }
    ) {
      nodes {
        name
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  }
`;

export default Technologies;
