import styled, { css } from "styled-components";
import Img, { FluidObject } from "gatsby-image";

import { device } from "../../../../../../utils/breakpoints";
import { fadeIn } from "../../../../../../utils/animations";
import { H4, H6, SUBTITLE_1 } from "../../../../../../assets/styles/typography";

interface PageProps {
  animation?: string;
}

export const PageWrapper = styled.div`
  position: relative;
  flex: 1;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  animation: ${(props: PageProps) =>
    !props.animation &&
    css`
      ${fadeIn} 300ms linear
    `};
  animation-fill-mode: forwards;
`;

export const TransitionWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-template-rows: repeat(4, auto);
  z-index: 1000;
  pointer-events: none;

  & > * {
    animation-name: ${(props: PageProps) =>
      props.animation &&
      css`
        ${fadeIn}
      `};
    animation-timing-function: linear;
  }
`;

interface TitleProps {
  color?: string;
}

export const Title = styled.h2`
  width: 100%;
  margin: 0 0 0 88px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  color: ${(props: TitleProps) => props.color || "#151515"};
  white-space: pre-line;

  &:first-of-type {
    margin-top: 88px;
  }

  @media ${device.computer} {
    ${H4}
    margin-left: 120px;

    &:first-of-type {
      margin-top: 125px;
    }
  }

  @media ${device.desktop} {
    margin-left: 120px;

    &:first-of-type {
      margin-top: 125px;
    }
  } ;
`;

export const Subtitle = styled.h3`
  margin: 16px 0 16px 88px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: #151515;
  white-space: pre-line;

  @media ${device.computer} {
    ${SUBTITLE_1}
    margin: 16px 0 64px 120px;
  } ;
`;

export const TechnologiesWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto 64px 88px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media ${device.computer} {
    width: calc(100vw - 414px);
    justify-content: space-between;
    align-items: center;
    margin: 0 120px 120px 120px;
  }

  @media ${device.desktop} {
    margin: 0 120px 120px 120px;
  } ;
`;

export const ItemWrapper = styled.div`
  position: relative;
  width: calc(50vw - 136px);
  height: calc(50vw - 136px);
  margin: 8px 0;
  background: #013cff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);

  &:nth-of-type(2n + 1) {
    margin-right: 16px;
  }

  @media ${device.computer} {
    width: calc((100vw - 414px - 96px) / 4);
    height: calc((100vw - 414px - 96px) / 4);
    margin: 16px;

    &:nth-of-type(1) {
      margin-top: 0;
      margin-left: 0;
    }

    &:nth-of-type(2) {
      margin-top: 0;
    }

    &:nth-of-type(3) {
      margin-top: 0;
    }

    &:nth-of-type(4) {
      margin-top: 0;
    }

    &:nth-of-type(5) {
      margin-left: 0;
      margin-bottom: 0;
    }

    &:nth-of-type(6) {
      margin-bottom: 0;
    }

    &:nth-of-type(7) {
      margin-bottom: 0;
    }

    &:nth-of-type(8) {
      margin-bottom: 0;
    }

    &:nth-of-type(4n) {
      margin-right: 0;
    }
  } ;
`;

export const TextWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
`;

export const Text = styled.div`
  ${H6}
  margin-left: 16px;
  color: #ffffff;
  white-space: pre-line;
  text-transform: uppercase;

  @media ${device.desktop} {
    ${H4}
    margin-left: 24px;
  } ;
`;

export const HorizontalDivider = styled.hr`
  width: 64px;
  height: 4px;
  margin: 16px auto 24px 0;
  background: #151515;

  @media ${device.desktop} {
    width: 96px;
    height: 6px;
    margin: 24px auto 32px 0;
  } ;
`;

export const ImagesWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  opacity: 0;
  background: #ffffff;
  z-index: 1;
  transition: opacity 0.5s linear;

  &:hover {
    opacity: 1;
  }
`;

export const Image = styled(Img)<{ isFullSize?: boolean }>`
  width: ${({ isFullSize }) => (!isFullSize ? "28%" : "100%")};
  max-height: ${({ isFullSize }) => (!isFullSize ? "18%" : "100%")};
  margin: auto;

  img {
    object-fit: contain !important;
  }
`;
